import Vue from 'vue/dist/vue.js'
if(typeof(window._U)=="undefined") window._U = new Object; // user function

$(document).on("turbolinks:load", function() {
  window._U.alert_modal = new Modal(document.getElementById('alertModal'));
  window._U.timer_target = "#next-mission-timer";
  window._U.timer_pre = "";
  window._U.timer_post = "";
  window._U.timer_now = "다음 미션을 선택해 주세요.";

  const select_card = function(mcs) {
    new Vue({
      el: '#app',
      data: {
        cards: mcs,
        shuffleSpeed: 'shuffleMedium',
        shuffleTypes: ['Slow', 'Medium', 'Fast'],
        isDeckShuffled: false,
        shuffleCount: 0,
      },
      mounted() {
        setTimeout(function () {
          this.shuffleDeck();
        }.bind(this), 400);

        // setTimeout(function() {
        //   this.shuffleDeck()
        // }, 3000);
      },  
      methods: {
        displayInitialDeck() {
          for(let i = this.cards.length - 1; i > 0; i--) {
            let randomIndex = Math.floor(Math.random() * i);
            
            let temp = this.cards[i];
            Vue.set(this.cards, i, this.cards[randomIndex]);
            Vue.set(this.cards, randomIndex, temp);
          }

          this.isDeckShuffled = false;
          this.shuffleCount = 0;
        },
        shuffleDeck() {        
          for(let i = this.cards.length - 1; i > 0; i--) {
            let randomIndex = Math.floor(Math.random() * i);
            
            let temp = this.cards[i];
            Vue.set(this.cards, i, this.cards[randomIndex]);
            Vue.set(this.cards, randomIndex, temp);
          }

          this.isDeckShuffled = true;
          this.shuffleCount = this.shuffleCount + 1;
        }
      },
    });
  }

  window._U.set_next_timer = function(nts) {
    const now_sec = Date.now()
    let distance = Math.floor(( nts - now_sec ) / 1000) 

    if(distance < 0) distance = 0;
    
    const d = Math.floor(distance / (60 * 60 * 24))
    const h = Math.floor((distance % (60 * 60 * 24)) / (60 * 60))
    const m = Math.floor((distance % (60 * 60)) / (60))
    const s = Math.floor(distance % (60))
    let t_text = [];


    if(d>0) t_text.push(d.toString() + '일 ')
    if(distance > 0) t_text.push(h.toString().padStart(2, '0') + ':')
    if(distance > 0) t_text.push(m.toString().padStart(2, '0') + ':')
    if(distance > 0) t_text.push(s.toString().padStart(2, '0') + '')

    // if(h>0||d>0) t_text.push(h.toString().padStart(2, '0') + ':')
    // if(m>0||h>0||d>0) t_text.push(m.toString().padStart(2, '0') + ':')
    // if(s>0||((d>0||h>0||m>0)&&s==0)) t_text.push(s.toString().padStart(2, '0') + '')
    // if(d>0) t_text.push(d + '일 ')
    // if(h>0) t_text.push(h + '시간 ')
    // if(m>0) t_text.push(m + '분 ')
    // if(s>0) t_text.push(s + '초 ')
    if(d+h+m+s>0) {
      t_text.push('')
    } else {
      _U.timer_pre = "";
      _U.timer_post = "";
      t_text.push(_U.timer_now);
    }
    // console.log('set_timer')
    const time_text = t_text.join('');
    if(time_text!="") $(window._U.timer_target).html( _U.timer_pre + time_text + _U.timer_post );
  }

  if($(".wrap-missions-select")[0]) {
    window._U.mission_modal = new Modal(document.getElementById('modalMission'));

    select_card(mcards);
  }

  if($(".wrap-missions-start")[0]) {
    select_card(mcards);
  }

  if($(".wrap-missions-category")[0]) {
    window._U.mission_modal = new Modal(document.getElementById('modalMission'));

    _U.timer_target = "#next-mission-category-timer";
    _U.timer_pre = "미션 수행 <span class='fs-4 fw-bolder'>";
    _U.timer_post = "</span> 남았습니다";

    let n_ts = 0;
    // _U.set_next_timer( next_timestamp );
    if(typeof(next_timestamp)!="undefined") {
      n_ts = next_timestamp;
    }
    setInterval(() => _U.set_next_timer( n_ts ), 1000);

    const missionModal = document.getElementById('modalMission')
    missionModal.addEventListener('hidden.bs.modal', event => {
      // do something...
      _U.timer_target = "#next-mission-category-timer";
    })

  }

});